
import { Component, Mixins, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { auth, ST_core } from '@/config/lib';
import { UiMixin } from '@/mixins/ui-mixin';
import ActivityCard from '@/views/admin/projects/components/activityCard.vue';

@Component({
  components: { ActivityCard }
})
export default class Activities extends Mixins<UiMixin>(UiMixin) {
  path = 'activities';
  stActivities = 'ST-Activities';
  addActivityDialog = false;
  userId = null;
  role = null;
  administrative = ['Administrador', 'Supervisor', 'Tech', 'Ingeniero'];
  form = {
    name: '',
    description: '',
  };
  registerData: any = {};
  activities: any = [];
  savedActivities = false;
  typeActivity = 2;
  activityId = null;
  headers = [{
    text: 'Nombre',
    value: 'name',
  }, {
    text: 'Descripción',
    value: 'description',
  }, {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  }];

  @Watch('activities', { deep: true })
  updateActivities() {
    ST_core.set(this.stActivities, [...this.activities]);
  }

  get validActivities() {
    if (this.registerData.hasOwnProperty('checkIn')) {
      const checkIn = moment.duration(moment(new Date()).diff(this.registerData.checkIn));
      const validRegister = checkIn.asHours() < 4;
      return this.administrative.some(item => item === this.role) || validRegister;
    }
    return false;
  }

  async addActivity() {
    this.activities.push({ ...this.form });
    ST_core.set(this.stActivities, [...this.activities]);
    this.addActivityDialog = false;
    if (this.savedActivities) {
      await this.updateActivitiesRecord();
    }
  }

  removeActivity(idx: number) {
    this.activities.splice(idx, 1);
    ST_core.set(this.stActivities, [...this.activities]);
  }

  async saveActivities() {
    if (!this.activities.length) {
      this.alert('Todavía no hay actividades para guardar');
      return;
    }
    this.setLoader(true);
    try {
      await this.$http.post('/activities', {
        schedule: this.registerData._id,
        activities: this.activities,
        userId: auth.get().user._id,
      });
      ST_core.delete(this.stActivities);
      await this.getCheckIn();
      this.alert('Actividades guardadas correctamente', 'success');
    } catch (e) {
      this.alert('Error guardando actividades');
    }
    this.setLoader(false);
  }

  async updateActivitiesRecord() {
    this.setLoader(true);
    try {
      await this.$http.patch(`/activities/${this.activityId}`, {
        activities: this.activities,
        userId: auth.get().user._id,
      });
      this.alert('Actividades guardadas correctamente', 'success');
    } catch (e) {
      this.alert('Error guardando actividades');
    }
    this.setLoader(false);
  }

  async getCheckIn() {
    this.setLoader(true);
    try {
      const req: any = await this.$http.get('/schedules', {
        params: {
          userId: this.userId,
          rows: 1,
          page: 1,
          date: moment(new Date()).format('YYYY-MM-DD'),
        }
      });
      if (req.data.docs.length) {
        this.registerData = req.data.docs[0];
        const reqActivities: any = await this.$http.get('/activities', {
          params: {
            schedule: this.registerData._id,
          },
        });
        if (reqActivities.data.docs.length && !req.data.docs[0].checkOut) {
          const activity = reqActivities.data.docs[0];
          this.savedActivities = true;
          this.activities = [...activity.activities];
          this.typeActivity = 3;
          this.activityId = activity._id;
        } else {
          const activities = ST_core.get(this.stActivities);
          if (activities) {
            this.activities = [...activities];
          }
        }
      }
    } catch (e) {
      this.alert('Error buscando registro', 'error');
    }
    this.setLoader(false);
  }

  async mounted() {
    this.userId = auth.get().user._id;
    this.role = auth.get().user.role.name;
    await this.getCheckIn();
  }
}
