
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({})
export default class ActivityCard extends Vue {
  @Prop()
  type!: number;
  @Prop()
  idx!: number;
  @VModel()
  form!: { name: string, description: string };
  addActivity() {
    this.$emit('addActivity', this.form);
    this.form.name = '';
    this.form.description = '';
  }
  deleteActivity() {
    this.$emit('deleteActivity', this.idx);
  }
}
